import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ProductCard.css';

const ProductCard = ({ product }) => {
  const { name_en, name_ar, image, price } = product;
  const resizedImage = image.replace('.png', '_200x200.png'); // Adjust image URL to use resized image

  return (
    <Link to={`/product/${product.id}`} className="text-decoration-none product-link">
      <div className="card product-card d-flex align-items-center">
        <img src={resizedImage} alt={name_en} className="card-img-left" />
        <div className="card-body">
          <div className="card-info">
            <h5 className="card-title">{name_en}</h5>
            <h6 className="card-title-ar">{name_ar}</h6>
          </div>
          <div className="price">{price}₪</div>
        </div>
      </div>
    </Link>
  );
};

export default ProductCard;