import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, onSnapshot, doc, setDoc } from 'firebase/firestore';
import { firestore, requestFcmToken, messaging } from '../firebaseConfig';
import { useAuth } from './auth/AuthContext';
import { onMessage } from 'firebase/messaging';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProductUploadForm from './ProductUploadForm';
import ProductEditForm from './ProductEditForm';
import '../styles/AdminDashboard.css';
import CategoryForm from './CategoryForm';

const AdminDashboard = () => {
  const [activeSection, setActiveSection] = useState('upload');
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar initially closed
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const getFcmToken = async () => {
      try {
        const token = await requestFcmToken();
        if (token && currentUser) {
          const userDocRef = doc(firestore, `users/${currentUser.uid}`);
          await setDoc(userDocRef, { fcmToken: token }, { merge: true });
        }
      } catch (error) {
        console.error('Error getting FCM token or saving it to Firestore:', error);
      }
    };

    getFcmToken();
  }, [currentUser]);

  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      toast.info(`${payload.notification.title}: ${payload.notification.body}`, {
        icon: payload.notification.icon,
      });
    });

    return () => unsubscribe();
  }, []);

  const goToProfile = () => {
    navigate('/profile');
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSectionClick = (section) => {
    setActiveSection(section);
    setIsSidebarOpen(false);
  };

  return (
    <div className="admin-dashboard">
      <ToastContainer />
      <button className="menu-toggler" onClick={toggleSidebar}>
        ☰
      </button>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <div className="sidebar-header">
          <h2>Admin Dashboard</h2>
          <i
            className="bi bi-person-circle profile-icon"
            onClick={goToProfile}
            title="User Profile"
          ></i>
        </div>
        <nav>
          <ul>
            <li className={activeSection === 'upload' ? 'active' : ''} onClick={() => handleSectionClick('upload')}>
              Upload Product
            </li>
            <li className={activeSection === 'edit' ? 'active' : ''} onClick={() => handleSectionClick('edit')}>
              Edit Products
            </li>
            <li className={activeSection === 'add-category' ? 'active' : ''} onClick={() => handleSectionClick('add-category')}>
              Add new Category
            </li>
          </ul>
        </nav>
      </div>
      <div className={`main-content ${isSidebarOpen ? 'shifted' : ''}`}>
        {activeSection === 'upload' && (
          <div className="section">
            <h2>Upload New Product</h2>
            <ProductUploadForm />
          </div>
        )}
        {activeSection === 'edit' && (
          <div className="section">
            <h2>Edit Existing Products</h2>
            <ProductEditForm />
          </div>
        )}
        {activeSection === 'add-category' && (
          <div className="section">
            <h2>Add new Category</h2>
            <CategoryForm />
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
