// HomePage.js
import React, { useState, useEffect } from 'react';
import { FaBars, FaSearch } from 'react-icons/fa';
import logoImage from '../images/alwakeel.png'; // Adjust the path if necessary
import NavBar from './NavBar'; // Import NavBar for sidebar functionality
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import ProductCard from './ProductCard'; // Import the ProductCard component for displaying individual products
import '../styles/HomePage.css'; // Ensure you have a HomePage.css for styling

const HomePage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, 'menuItems'), (snapshot) => {
      const productsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProducts(productsData);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredProducts([]);
    } else {
      const filtered = products.filter(product =>
        product.name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.name_ar.includes(searchTerm)
      );
      setFilteredProducts(filtered);
    }
  }, [searchTerm, products]);

  return (
    <div className="home-page">
      <NavBar /> {/* Render NavBar for sidebar functionality */}
      <div className="home-page-content">
        <img src={logoImage} alt="Company Logo" className="company-logo" />
        <h3>الخليل - شارع السلام - مربعة سبتا</h3>
        <button
          className="open-sidebar-button"
          onClick={() => document.querySelector('.navbar-toggler').click()}
        >
          <FaBars className="open-sidebar-icon" />
          <span>Explore Categories</span>
        </button>

        {/* Search Bar */}
        <div className="search-container">
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search for products..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
            <FaSearch className="search-icon" />
          </div>
        </div>
        
        {/* Display Search Results */}
        <div className="search-results">
          {filteredProducts.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
