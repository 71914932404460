import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, doc, deleteDoc, updateDoc, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from './Modal';
import ConfirmationModal from './ConfirmationModal'; // Ensure this import is correct
import '../styles/CategoryForm.css';

const CategoryForm = () => {
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isAddCategoryOpen, setIsAddCategoryOpen] = useState(false);
  const [isAddSubcategoryOpen, setIsAddSubcategoryOpen] = useState(false);
  const [isEditCategoryOpen, setIsEditCategoryOpen] = useState(false);
  const [isEditSubcategoryOpen, setIsEditSubcategoryOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState(null); // Store target item to delete
  const [deleteType, setDeleteType] = useState(''); // 'category' or 'subcategory'
  const [newCategory, setNewCategory] = useState('');
  const [newSubcategory, setNewSubcategory] = useState('');
  const [categoryToEdit, setCategoryToEdit] = useState(null);
  const [subcategoryToEdit, setSubcategoryToEdit] = useState(null);

  useEffect(() => {
    const unsubscribeCategories = onSnapshot(collection(firestore, 'categories'), (snapshot) => {
      const categoryData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoryData);
    });

    const unsubscribeSubcategories = onSnapshot(collection(firestore, 'subcategories'), (snapshot) => {
      const subcategoryData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setSubcategories(subcategoryData);
    });

    return () => {
      unsubscribeCategories();
      unsubscribeSubcategories();
    };
  }, []);

  const handleAddCategory = async () => {
    if (!newCategory.trim()) return;
    try {
      await addDoc(collection(firestore, 'categories'), { name: newCategory });
      toast.success('Category added successfully!');
      setNewCategory('');
      setIsAddCategoryOpen(false);
    } catch (error) {
      console.error('Error adding category:', error);
      toast.error('Failed to add category.');
    }
  };

  const handleAddSubcategory = async () => {
    if (!newSubcategory.trim() || !selectedCategory) return;
    try {
      await addDoc(collection(firestore, 'subcategories'), { name: newSubcategory, category: selectedCategory });
      toast.success('Subcategory added successfully!');
      setNewSubcategory('');
      setSelectedCategory('');
      setIsAddSubcategoryOpen(false);
    } catch (error) {
      console.error('Error adding subcategory:', error);
      toast.error('Failed to add subcategory.');
    }
  };

  const handleEditCategory = async () => {
    if (!newCategory.trim()) return;
    try {
      await updateDoc(doc(firestore, 'categories', categoryToEdit.id), { name: newCategory });
      toast.success('Category updated successfully!');
      resetModalState();
    } catch (error) {
      console.error('Error updating category:', error);
      toast.error('Failed to update category.');
    }
  };

  const handleEditSubcategory = async () => {
    if (!newSubcategory.trim()) return;
    try {
      const subcategoryRef = doc(firestore, 'subcategories', subcategoryToEdit.id);
      await updateDoc(subcategoryRef, { name: newSubcategory, category: selectedCategory });
      toast.success('Subcategory updated successfully!');
      resetModalState();
    } catch (error) {
      console.error('Error updating subcategory:', error);
      toast.error('Failed to update subcategory.');
    }
  };

  const confirmDelete = (type, id) => {
    setDeleteTarget(id);
    setDeleteType(type);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    setIsDeleteModalOpen(false);
    if (deleteType === 'category') {
      try {
        await deleteDoc(doc(firestore, 'categories', deleteTarget));
        toast.success('Category deleted successfully!');
      } catch (error) {
        console.error("Error deleting category:", error);
        toast.error('Failed to delete category.');
      }
    } else if (deleteType === 'subcategory') {
      try {
        await deleteDoc(doc(firestore, 'subcategories', deleteTarget));
        toast.success('Subcategory deleted successfully!');
      } catch (error) {
        console.error("Error deleting subcategory:", error);
        toast.error('Failed to delete subcategory.');
      }
    }
  };

  const resetModalState = () => {
    setIsEditCategoryOpen(false);
    setIsEditSubcategoryOpen(false);
    setNewCategory('');
    setNewSubcategory('');
    setSelectedCategory('');
    setCategoryToEdit(null);
    setSubcategoryToEdit(null);
  };

  return (
    <div className="category-form-container">
      <ToastContainer />
      <div className="header">
        <h2>Manage Categories & Subcategories</h2>
        <button onClick={() => setIsAddCategoryOpen(true)} className="action-button">Add Category</button>
        <button onClick={() => setIsAddSubcategoryOpen(true)} className="action-button">Add Subcategory</button>
      </div>

      <div className="section">
        <h3>Categories</h3>
        <div className="category-list">
          {categories.map(category => (
            <div key={category.id} className="category-item">
              <div className="category-header">
                <h4>{category.name}</h4>
                <div className="button-group">
                  <button
                    className="edit-button"
                    onClick={() => {
                      setCategoryToEdit(category);
                      setNewCategory(category.name);
                      setIsEditCategoryOpen(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => confirmDelete('category', category.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="section">
        <h3>Subcategories</h3>
        <div className="category-list">
          {subcategories.map(subcategory => (
            <div key={subcategory.id} className="subcategory-item">
              <div className="category-header">
                <h4>{subcategory.name}</h4>
                <p>Category: {subcategory.category}</p>
                <div className="button-group">
                  <button
                    className="edit-button"
                    onClick={() => {
                      setSubcategoryToEdit(subcategory);
                      setNewSubcategory(subcategory.name);
                      setSelectedCategory(subcategory.category);
                      setIsEditSubcategoryOpen(true);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => confirmDelete('subcategory', subcategory.id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Add and Edit Modals */}
      {isAddCategoryOpen && (
        <Modal onClose={() => setIsAddCategoryOpen(false)}>
          <h3>Add New Category</h3>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Category name"
            className="form-input"
          />
          <button onClick={handleAddCategory} className="form-button">Add Category</button>
        </Modal>
      )}

      {isEditCategoryOpen && (
        <Modal onClose={resetModalState}>
          <h3>Edit Category</h3>
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Category name"
            className="form-input"
          />
          <button onClick={handleEditCategory} className="form-button">Save Changes</button>
        </Modal>
      )}

      {isAddSubcategoryOpen && (
        <Modal onClose={() => setIsAddSubcategoryOpen(false)}>
          <h3>Add New Subcategory</h3>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="form-input"
          >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.name}>{cat.name}</option>
            ))}
          </select>
          <input
            type="text"
            value={newSubcategory}
            onChange={(e) => setNewSubcategory(e.target.value)}
            placeholder="Subcategory name"
            className="form-input"
          />
          <button onClick={handleAddSubcategory} className="form-button">Add Subcategory</button>
        </Modal>
      )}

      {isEditSubcategoryOpen && (
        <Modal onClose={resetModalState}>
          <h3>Edit Subcategory</h3>
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="form-input"
          >
            <option value="">Select Category</option>
            {categories.map(cat => (
              <option key={cat.id} value={cat.name}>{cat.name}</option>
            ))}
          </select>
          <input
            type="text"
            value={newSubcategory}
            onChange={(e) => setNewSubcategory(e.target.value)}
            placeholder="Subcategory name"
            className="form-input"
          />
          <button onClick={handleEditSubcategory} className="form-button">Save Changes</button>
        </Modal>
      )}

      {/* Delete Confirmation Modal */}
      {isDeleteModalOpen && (
        <ConfirmationModal
          message="Are you sure you want to delete this item?"
          onConfirm={handleDelete}
          onCancel={() => setIsDeleteModalOpen(false)}
        />
      )}
    </div>
  );
};

export default CategoryForm;
