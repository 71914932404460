import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../firebaseConfig';
import '../../styles/Login.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const { login, currentUser } = useAuth(); // Auth context with currentUser and login function
  const [userRole, setUserRole] = useState(null); // Store fetched user role
  const navigate = useNavigate();

  // Redirect if the user is already logged in
  useEffect(() => {
    const checkCurrentUserRole = async () => {
      if (currentUser && !userRole) { // Avoid re-fetching if role is already set
        try {
          const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (userData.role) {
              setUserRole(userData.role); // Set the role in state
              navigate(userData.role === 'admin' ? '/admin' : '/'); // Navigate based on role
            }
          } else {
            setMessage({ type: 'error', text: 'User data not found. Please contact support.' });
          }
        } catch (error) {
          console.error('Error fetching user role:', error);
          setMessage({ type: 'error', text: 'Error fetching user role. Please try again.' });
        }
      }
    };

    checkCurrentUserRole();
  }, [currentUser, userRole, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(null);

    try {
      const userCredential = await login(email, password);
      if (userCredential && userCredential.user) {
        // Wait for role to be set and navigate
        const user = userCredential.user;
        const userDoc = await getDoc(doc(firestore, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.role) {
            setUserRole(userData.role); // Set the user role, which triggers redirection
          } else {
            setMessage({ type: 'error', text: 'User role is missing. Please contact support.' });
          }
        } else {
          setMessage({ type: 'error', text: 'User role not found. Please contact support.' });
        }
      } else {
        throw new Error("User credential is undefined. Please check your login function.");
      }
    } catch (error) {
      console.error('Error logging in:', error);
      setMessage({ type: 'error', text: error.message || 'Error logging in. Please try again.' });
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="auth-form-container">
      <div className="auth-form-card">
        <h2>Login</h2>
        {message && (
          <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
            {message.text}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setMessage(null); // Clear message on change
              }}
              required
            />
          </div>
          <div className="mb-3">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setMessage(null); // Clear message on change
              }}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
