// import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom';
// import { collection, onSnapshot, query, where } from 'firebase/firestore';
// import { getStorage, ref, getDownloadURL } from 'firebase/storage';
// import { firestore } from '../firebaseConfig';
// import ProductCard from './ProductCard';
// import NavBar from './NavBar';
// import { FaSearch } from 'react-icons/fa';
// import '../styles/ProductsDisplay.css';
// import logoImage from '../images/alwakeel.png';

// const ProductsDisplay = () => {
//   const [groupedProducts, setGroupedProducts] = useState({});
//   const [filteredProducts, setFilteredProducts] = useState({});
//   const [isLoading, setIsLoading] = useState(true);
//   const [searchTerm, setSearchTerm] = useState('');
//   const { categoryName } = useParams();
//   const [navExpanded, setNavExpanded] = useState(false);
//   const [subcategoriesOrder, setSubcategoriesOrder] = useState([]);

//   const toggleNav = () => {
//     setNavExpanded(!navExpanded);
//   };

//   // Fetch subcategories for ordering purposes
//   useEffect(() => {
//     const fetchSubcategories = async () => {
//       const subcategoriesCollectionRef = collection(firestore, 'subcategories');
//       const unsubscribeSubcategories = onSnapshot(subcategoriesCollectionRef, (snapshot) => {
//         const subcategoryList = snapshot.docs.map((doc) => doc.data().name);
//         setSubcategoriesOrder(subcategoryList);
//       });
//       return unsubscribeSubcategories;
//     };

//     fetchSubcategories();
//   }, []);

//   // Function to check if the original image exists and fetch the resized image if available
//   const fetchResizedImageURL = async (originalImageURL, retryCount = 5) => {
//     if (!originalImageURL) {
//       console.error("Original image URL is missing.");
//       return null;
//     }

//     const storage = getStorage();
//     const originalImageRef = ref(storage, originalImageURL);

//     // Check if the original image exists
//     try {
//       await getDownloadURL(originalImageRef);
//     } catch (error) {
//       console.error(`Original image not found at ${originalImageURL}. Skipping this product.`);
//       return null;
//     }

//     // Construct the resized image path with the `_200x200` suffix
//     const resizedImagePath = originalImageURL
//       .replace(/(\.\w+)$/, '_200x200$1'); // Adds `_200x200` before the file extension

//     const resizedImageRef = ref(storage, resizedImagePath);

//     // Attempt to fetch the resized image with retries
//     for (let i = 0; i < retryCount; i++) {
//       try {
//         const resizedImageURL = await getDownloadURL(resizedImageRef);
//         return resizedImageURL;
//       } catch (error) {
//         if (error.code === 'storage/object-not-found' && i < retryCount - 1) {
//           console.warn(`Resized image not found, retrying... (${i + 1}/${retryCount})`);
//           await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
//         } else {
//           console.error(`Error fetching resized image for ${resizedImagePath}:`, error);
//           return null;
//         }
//       }
//     }
//     return null; // Return null if the resized image isn't found after retries
//   };

//   // Fetch products by category and group by subcategory, only displaying products with resized images
//   useEffect(() => {
//     setIsLoading(true);

//     const productsQuery = categoryName
//       ? query(collection(firestore, 'menuItems'), where('category', '==', categoryName))
//       : collection(firestore, 'menuItems');

//     const unsubscribe = onSnapshot(productsQuery, async (querySnapshot) => {
//       const productsList = await Promise.all(
//         querySnapshot.docs.map(async (doc) => {
//           const data = doc.data();
//           const resizedImageURL = await fetchResizedImageURL(data.image);
          
//           // Only return products with a valid resized image URL
//           if (resizedImageURL) {
//             return {
//               id: doc.id,
//               ...data,
//               resizedImage: resizedImageURL,
//             };
//           }
//           return null;
//         })
//       );

//       // Filter out any null values (products without resized images)
//       const filteredProductsList = productsList.filter(product => product !== null);

//       const grouped = filteredProductsList.reduce((acc, product) => {
//         const { subcategory } = product;
//         acc[subcategory] = acc[subcategory] || [];
//         acc[subcategory].push(product);
//         return acc;
//       }, {});

//       const sortedGroupedProducts = Object.keys(grouped)
//         .sort((a, b) => subcategoriesOrder.indexOf(a) - subcategoriesOrder.indexOf(b))
//         .reduce((acc, key) => {
//           acc[key] = grouped[key];
//           return acc;
//         }, {});

//       setGroupedProducts(sortedGroupedProducts);
//       setFilteredProducts(sortedGroupedProducts);
//       setIsLoading(false);
//     });

//     return () => unsubscribe();
//   }, [categoryName, subcategoriesOrder]);

//   // Search filter
//   const handleSearch = (e) => {
//     const searchValue = e.target.value.toLowerCase();
//     setSearchTerm(searchValue);

//     const filtered = Object.keys(groupedProducts).reduce((acc, subcategory) => {
//       const products = groupedProducts[subcategory].filter((product) =>
//         product.name_en.toLowerCase().includes(searchValue) ||
//         (product.name_ar && product.name_ar.includes(searchValue))
//       );
//       if (products.length > 0) {
//         acc[subcategory] = products;
//       }
//       return acc;
//     }, {});

//     setFilteredProducts(filtered);
//   };

//   return (
//     <div className="product-display-container">
//       <NavBar toggleNav={toggleNav} navExpanded={navExpanded} />
//       {isLoading ? (
//         <div className="loading-container">
//           <img src={logoImage} alt="Loading..." className="logo-spinner" />
//         </div>
//       ) : (
//         <>
//           <h1>{categoryName}</h1>
//           <div className="search-container">
//             <div className="search-bar">
//               <input
//                 type="text"
//                 placeholder="Search products..."
//                 value={searchTerm}
//                 onChange={handleSearch}
//                 className="search-input"
//               />
//               <FaSearch className="search-icon" />
//             </div>
//           </div>
//           {Object.entries(filteredProducts).map(([subcategoryName, products]) => (
//             <section key={subcategoryName}>
//               <h2>{subcategoryName}</h2>
//               <div className="row">
//                 {products.map((product) => (
//                   <div key={product.id} className="col-6 col-md-4 col-lg-3">
//                     <ProductCard product={product} />
//                   </div>
//                 ))}
//               </div>
//             </section>
//           ))}
//         </>
//       )}
//     </div>
//   );
// };

// export default ProductsDisplay;


import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { firestore } from '../firebaseConfig';
import ProductCard from './ProductCard';
import NavBar from './NavBar';
import { FaSearch } from 'react-icons/fa';
import '../styles/ProductsDisplay.css';
import logoImage from '../images/alwakeel.png';

const ProductsDisplay = () => {
  const [groupedProducts, setGroupedProducts] = useState({});
  const [filteredProducts, setFilteredProducts] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const { categoryName } = useParams();
  const [navExpanded, setNavExpanded] = useState(false);
  const [subcategoriesOrder, setSubcategoriesOrder] = useState([]);

  const toggleNav = () => {
    setNavExpanded(!navExpanded);
  };

  // Fetch subcategories for ordering purposes
  useEffect(() => {
    const fetchSubcategories = async () => {
      const subcategoriesCollectionRef = collection(firestore, 'subcategories');
      const unsubscribeSubcategories = onSnapshot(subcategoriesCollectionRef, (snapshot) => {
        const subcategoryList = snapshot.docs.map((doc) => doc.data().name);
        setSubcategoriesOrder(subcategoryList);
      });
      return unsubscribeSubcategories;
    };

    fetchSubcategories();
  }, []);

  // Function to check if the original image exists and fetch the resized image if available
  const fetchResizedImageURL = async (originalImageURL, retryCount = 5) => {
    if (!originalImageURL) {
      console.error("Original image URL is missing.");
      return null;
    }

    const storage = getStorage();
    const originalImageRef = ref(storage, originalImageURL);

    // Check if the original image exists
    try {
      await getDownloadURL(originalImageRef);
    } catch (error) {
      console.error(`Original image not found at ${originalImageURL}. Skipping this product.`);
      return null;
    }

    // Construct the resized image path with the `_200x200` suffix
    const resizedImagePath = originalImageURL.replace(/(\.\w+)$/, '_200x200$1'); // Adds `_200x200` before the file extension
    const resizedImageRef = ref(storage, resizedImagePath);

    // Attempt to fetch the resized image with retries
    for (let i = 0; i < retryCount; i++) {
      try {
        const resizedImageURL = await getDownloadURL(resizedImageRef);
        return resizedImageURL;
      } catch (error) {
        if (error.code === 'storage/object-not-found' && i < retryCount - 1) {
          console.warn(`Resized image not found, retrying... (${i + 1}/${retryCount})`);
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait 2 seconds before retrying
        } else {
          console.error(`Error fetching resized image for ${resizedImagePath}:`, error);
          return null;
        }
      }
    }
    return null; // Return null if the resized image isn't found after retries
  };

  // Fetch products by category and group by subcategory, only displaying products with resized images
  useEffect(() => {
    setIsLoading(true);

    const productsQuery = categoryName
      ? query(collection(firestore, 'menuItems'), where('category', '==', categoryName))
      : collection(firestore, 'menuItems');

    const unsubscribe = onSnapshot(productsQuery, async (querySnapshot) => {
      const productsList = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const resizedImageURL = await fetchResizedImageURL(data.image);
          
          // Only return products with a valid resized image URL
          if (resizedImageURL) {
            return {
              id: doc.id,
              ...data,
              resizedImage: resizedImageURL,
            };
          }
          return null;
        })
      );

      // Filter out any null values (products without resized images)
      const filteredProductsList = productsList.filter(product => product !== null);

      const grouped = filteredProductsList.reduce((acc, product) => {
        const { subcategory } = product;
        acc[subcategory] = acc[subcategory] || [];
        acc[subcategory].push(product);
        return acc;
      }, {});

      const sortedGroupedProducts = Object.keys(grouped)
        .sort((a, b) => subcategoriesOrder.indexOf(a) - subcategoriesOrder.indexOf(b))
        .reduce((acc, key) => {
          acc[key] = grouped[key];
          return acc;
        }, {});

      setGroupedProducts(sortedGroupedProducts);
      setFilteredProducts(sortedGroupedProducts);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [categoryName, subcategoriesOrder]);

  // Search filter
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);

    const filtered = Object.keys(groupedProducts).reduce((acc, subcategory) => {
      const products = groupedProducts[subcategory].filter((product) =>
        product.name_en.toLowerCase().includes(searchValue) ||
        (product.name_ar && product.name_ar.includes(searchValue))
      );
      if (products.length > 0) {
        acc[subcategory] = products;
      }
      return acc;
    }, {});

    setFilteredProducts(filtered);
  };

  return (
    <div className="product-display-container">
      <NavBar toggleNav={toggleNav} navExpanded={navExpanded} />
      {isLoading ? (
        <div className="loading-container">
          <img src={logoImage} alt="Loading..." className="logo-spinner" />
        </div>
      ) : (
        <>
          <h1>{categoryName}</h1>
          <div className="search-container">
            <div className="search-bar">
              <input
                type="text"
                placeholder="Search products..."
                value={searchTerm}
                onChange={handleSearch}
                className="search-input"
              />
              <FaSearch className="search-icon" />
            </div>
          </div>
          {Object.entries(filteredProducts).map(([subcategoryName, products]) => (
            <section key={subcategoryName}>
              <h2>{subcategoryName}</h2>
              <div className="row">
                {products.map((product) => (
                  <div key={product.id} className="col-6 col-md-4 col-lg-3">
                    <ProductCard product={product} />
                  </div>
                ))}
              </div>
            </section>
          ))}
        </>
      )}
    </div>
  );
};

export default ProductsDisplay;

