// import { initializeApp } from 'firebase/app';
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from 'firebase/storage';
// import { getAuth } from 'firebase/auth'; // Import getAuth for Firebase Authentication

// const firebaseConfig = {
//   apiKey: "AIzaSyDH_JNdk4yPfvcjyTlCYNpRpn6N-_1zqwU",
//   authDomain: "lastoria-acfd1.firebaseapp.com",
//   projectId: "lastoria-acfd1",
//   storageBucket: "lastoria-acfd1.appspot.com",
//   messagingSenderId: "1048196802674",
//   appId: "1:1048196802674:web:c124bdd30ea3efa4fd3aeb"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);

// // Firestore instance
// const firestore = getFirestore(app);

// // Firebase Storage instance
// const storage = getStorage(app);

// // Firebase Auth instance
// const auth = getAuth(app);

// export { firestore, storage, auth }; // Export Firestore, Storage, and Auth


import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCzFANAipKTXWo8URX5q-usmemhrd5HoSo",
  authDomain: "al-wakeel-b43fb.firebaseapp.com",
  projectId: "al-wakeel-b43fb",
  storageBucket: "al-wakeel-b43fb.firebasestorage.app",
  messagingSenderId: "751968656176",
  appId: "1:751968656176:web:458f4121bdd2fb876386f5"
};

// Initialize Firebase app
const app = initializeApp(firebaseConfig);

// Initialize Firebase Analytics
const analytics = getAnalytics(app);

// Initialize Firestore for database operations
const firestore = getFirestore(app);

// Initialize Firebase Storage for file handling
const storage = getStorage(app);

// Initialize Firebase Auth for user authentication
const auth = getAuth(app);

// Initialize Firebase Cloud Messaging for push notifications
const messaging = getMessaging(app);

// Function to request user permission and get FCM token
export const requestFcmToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const fcmToken = await getToken(messaging, {
        vapidKey: 'BIlikNZgkhBOH8ibX7Pm7D7AEOS3ea9iRQ6by3ZTq4bPiPLz-5XpH6Bzr4HqlEdaShPBI_LRS3a6ZOUHNXUljMc'
      });
      console.log('FCM Token:', fcmToken);
      return fcmToken;
    } else {
      console.warn('Notification permission denied');
      return null;
    }
  } catch (error) {
    console.error('Error getting FCM token:', error);
    return null;
  }
};

// Function to save FCM token to Firestore
export const saveFcmToken = async (currentUser, token) => {
  if (currentUser && token) {
    const userDocRef = doc(firestore, `users/${currentUser.uid}`);
    try {
      await setDoc(userDocRef, { fcmToken: token }, { merge: true });
      console.log('FCM Token saved to Firestore');
    } catch (error) {
      console.error('Error saving FCM token to Firestore:', error);
    }
  }
};

// Use this function when the user logs in or when AdminDashboard is loaded
export const handleFcmToken = async (currentUser) => {
  const token = await requestFcmToken();
  if (token) {
    await saveFcmToken(currentUser, token);
  }
};

// Listen for foreground messages
onMessage(messaging, (payload) => {
  try {
    console.log('Message received: ', payload);
    // Optional: Display a native browser notification
    new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: payload.notification.icon,
    });
  } catch (error) {
    console.error('Error receiving foreground message:', error);
  }
});

export { firestore, storage, auth, messaging, app };

