import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { uploadBytes, getDownloadURL, ref } from 'firebase/storage';
import { firestore, storage } from '../firebaseConfig';
import '../styles/ProductEditForm.css';

const ProductEditForm = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [filteredSubcategories, setFilteredSubcategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(firestore, 'menuItems'));
        const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setProducts(productsData);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchCategories = async () => {
      try {
        const categorySnapshot = await getDocs(collection(firestore, 'categories'));
        setCategories(categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    const fetchSubcategories = async () => {
      try {
        const subcategorySnapshot = await getDocs(collection(firestore, 'subcategories'));
        setSubcategories(subcategorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      }
    };

    fetchProducts();
    fetchCategories();
    fetchSubcategories();
  }, []);

  // Helper function to fetch the resized image URL if available
  const fetchResizedImageURL = async (originalImageUrl) => {
    if (!originalImageUrl) {
      console.warn("Original image URL is missing.");
      return null;
    }

    // Construct resized image URL
    const resizedImageUrl = originalImageUrl.replace(/(\.\w+)$/, '_200x200$1'); // Adds "_200x200" before the extension
    const storageRef = ref(storage, resizedImageUrl);

    try {
      // Attempt to get the download URL for the resized image
      const downloadUrl = await getDownloadURL(storageRef);
      return downloadUrl;
    } catch (error) {
      console.error(`Failed to load resized image at ${resizedImageUrl}:`, error);
      return null;
    }
  };

  const handleChange = async (e, productId) => {
    const { name, value, files } = e.target;

    if (name === 'image' && files.length > 0) {
      const newImageFile = files[0];
      const storageRef = ref(storage, `product_images/${productId}/${newImageFile.name}`);

      try {
        await uploadBytes(storageRef, newImageFile);
        const newImageUrl = await getDownloadURL(storageRef);

        await updateDoc(doc(firestore, 'menuItems', productId), { image: newImageUrl });
        setProducts(products.map(product =>
          product.id === productId ? { ...product, image: newImageUrl } : product
        ));
      } catch (error) {
        console.error("Error uploading image: ", error);
      }
    } else {
      await updateDoc(doc(firestore, 'menuItems', productId), { [name]: value });
      setProducts(products.map(product =>
        product.id === productId ? { ...product, [name]: value } : product
      ));

      // Update filtered subcategories if category changes
      if (name === 'category') {
        setFilteredSubcategories(subcategories.filter(sub => sub.category === value));
      }
    }
  };

  const handleDelete = async (productId) => {
    if (window.confirm('Are you sure you want to delete this product?')) {
      try {
        await deleteDoc(doc(firestore, 'menuItems', productId));
        setProducts(products.filter(product => product.id !== productId));
        alert('Product deleted successfully!');
      } catch (error) {
        console.error('Error deleting product: ', error);
        alert('Error deleting product. Please try again.');
      }
    }
  };

  const handleSave = async (productId) => {
    try {
      alert('Changes saved successfully!');
    } catch (error) {
      console.error('Error saving product: ', error);
      alert('Error saving product. Please try again.');
    }
  };

  const filteredProducts = products.filter(product => {
    return (
      product.name_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.name_ar.includes(searchTerm) ||
      product.description_en.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description_ar.includes(searchTerm) ||
      product.price.toString().includes(searchTerm)
    );
  });

  return (
    <div className="container mt-3">
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by name, description, or price"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {filteredProducts.map(product => (
        <div key={product.id} className="card mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-md-4">
                {/* Display resized image if available */}
                <img
                  src={product.resizedImageUrl || product.image}
                  alt={product.name_en}
                  style={{ width: '100%', height: 'auto' }}
                  onError={async (e) => {
                    console.warn(`Failed to load resized image for ${product.image}. Fetching resized image URL...`);
                    const resizedImageUrl = await fetchResizedImageURL(product.image);
                    if (resizedImageUrl) {
                      e.target.src = resizedImageUrl;
                    } else {
                      e.target.style.display = 'none'; // Hide if resized image is not found
                    }
                  }}
                />
                <input type="file" className="form-control mt-2" name="image" onChange={(e) => handleChange(e, product.id)} />
              </div>
              <div className="col-md-8">
                <div className="mb-3">
                  <label className="form-label">Name (English)</label>
                  <input type="text" className="form-control" name="name_en" value={product.name_en} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Name (Arabic)</label>
                  <input type="text" className="form-control" name="name_ar" value={product.name_ar} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description (English)</label>
                  <textarea className="form-control" name="description_en" value={product.description_en} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Description (Arabic)</label>
                  <textarea className="form-control" name="description_ar" value={product.description_ar} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Price</label>
                  <input type="number" className="form-control" name="price" value={product.price} onChange={(e) => handleChange(e, product.id)} />
                </div>
                <div className="mb-3">
                  <label className="form-label">Category</label>
                  <select
                    className="form-select"
                    name="category"
                    value={product.category}
                    onChange={(e) => handleChange(e, product.id)}
                  >
                    <option value="">Select Category</option>
                    {categories.map(category => (
                      <option key={category.id} value={category.name}>{category.name}</option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Subcategory</label>
                  <select
                    className="form-select"
                    name="subcategory"
                    value={product.subcategory}
                    onChange={(e) => handleChange(e, product.id)}
                  >
                    <option value="">Select Subcategory</option>
                    {filteredSubcategories.map(subcategory => (
                      <option key={subcategory.id} value={subcategory.name}>{subcategory.name}</option>
                    ))}
                  </select>
                </div>
                <div className="d-flex justify-content-end">
                  <button className="btn btn-primary me-2" onClick={() => handleSave(product.id)}>Save</button>
                  <button className="btn btn-danger" onClick={() => handleDelete(product.id)}>Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductEditForm;
