import React from 'react';
import '../styles/Footer.css'; // Make sure the path to your CSS file is correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Import the phone icon

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
        <span className="footer-logo">Al-Wakeel For Computer</span>
        <div className="footer-socials">
          {/* Existing social media icons */}
          <a href="https://www.facebook.com/alwakeelforcomputer20/" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://www.instagram.com/alwakeel.for.computer?fbclid=IwY2xjawGafplleHRuA2FlbQIxMAABHU2vFSmKi_VVZ28tLpN9evk9ZZ8oxvuFA8fxX7bzfwNlPLqYa2G7nzOQMg_aem_G-nV0Jw75tYfaqvqYAfy0Q" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.tiktok.com/@alwakeel.for.computer?fbclid=IwY2xjawGafrRleHRuA2FlbQIxMAABHR6m7W6y9sy6NVGN8VTUb2pu1OclT8cKZG--tO-kIY988ZOwgr1t_m6V7w_aem_y_GvE_2-r2PYuy3NvIr5ZA" aria-label="TikTok" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faTiktok} />
          </a>
          {/* Call icon */}
          <a href="tel:+970599306177" aria-label="Call Us">
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </div>
        <div className="footer-copy">
          © 2024 All rights reserved. Done by <a href="https://growup-ps.com/social-media" target="_blank" rel="noopener noreferrer" className='growup'>Grow Up</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
