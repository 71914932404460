// import React, { useState, useEffect } from 'react';
// import { Navbar, Nav } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { collection, getDocs } from 'firebase/firestore';
// import { firestore } from '../firebaseConfig';
// import '../styles/NavBar.css';

// const NavBar = ({ toggleNav, navExpanded }) => {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
//     const fetchCategories = async () => {
//       const categoriesCollectionRef = collection(firestore, 'categories');
//       const querySnapshot = await getDocs(categoriesCollectionRef);
//       const categoriesList = querySnapshot.docs.map(doc => doc.data().name);
//       setCategories(categoriesList);
//     };

//     fetchCategories();
//   }, []);

//   return (
//     <Navbar expanded={navExpanded} expand="lg" variant="dark" className="navbar-custom">
//       <Navbar.Brand href="/">Beirut Cafe</Navbar.Brand>
//       <Navbar.Toggle onClick={toggleNav} aria-controls="responsive-navbar-nav" />
//       <Navbar.Collapse id="responsive-navbar-nav">
//         <Nav className="mr-auto" onClick={toggleNav}>
//           {categories.map((category) => (
//             <Nav.Link as={Link} to={`/category/${category}`} key={category}>
//               {category}
//             </Nav.Link>
//           ))}
//         </Nav>
//       </Navbar.Collapse>
//     </Navbar>
//   );
// };

// export default NavBar;


import React, { useState, useEffect } from 'react';
import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { Link, useLocation } from 'react-router-dom';
import '../styles/NavBar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import logoImage from '../images/alwakeel.png'; // Adjust path if needed

const NavBar = () => {
  const [categories, setCategories] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, 'categories'), (snapshot) => {
      const categoriesList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesList);
    });

    return () => unsubscribe();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleCategoryClick = (categoryName) => {
    setActiveCategory(categoryName);
    toggleSidebar(); // Close the sidebar after clicking
  };

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <Link to="/" className="navbar-brand">
          <img src={logoImage} alt="Logo" className="navbar-logo" />
        </Link>
        <button className="navbar-toggler" onClick={toggleSidebar}>
          <FaBars className="navbar-toggler-icon" />
        </button>
      </nav>

      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleSidebar}>
          <FaTimes />
        </button>
        <h2 className="sidebar-title">Categories</h2>
        <ul className="sidebar-list">
          {categories.map((category) => (
            <li
              key={category.id}
              className={`sidebar-item ${activeCategory === category.name ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category.name)}
            >
              <Link to={`/category/${category.name}`}>
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {isSidebarOpen && <div className="backdrop" onClick={toggleSidebar}></div>}
    </div>
  );
};

export default NavBar;



