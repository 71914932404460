// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import './index.css'; // or any global stylesheets you have

// // Assuming your root div has the ID 'root' in your HTML
// const root = ReactDOM.createRoot(document.getElementById('root'));

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'; // Ensure you have global styles
import { requestFcmToken } from './firebaseConfig'; // Import FCM token request function

// Check if service workers are supported and register the FCM service worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then(async (registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
      
      // Ensure requestFcmToken is only called after service worker registration is successful
      try {
        await requestFcmToken(); // Request the FCM token for push notifications
      } catch (error) {
        console.error('Failed to get FCM token:', error);
      }
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}

// Render the application
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

